import React from 'react';
import FormBuilder from './FormBuilder';

const FormGenerator = ({ forms }) => {
    const formKey = Object.keys(forms)[0];
    const formConfig = forms[formKey];
    const applicationDocument = forms["typeOfApplication"];

    return (
        <div>
            <FormBuilder formConfig={formConfig} applicationDoc={applicationDocument} formKey={formKey} />
        </div>
    );
};

export default FormGenerator;