import React from 'react';

// Destructure environment variables for easier access
const {
  REACT_APP_CONNECT_CANADA_URL: CONNECT_CANADA_URL,
  REACT_APP_AUTH_BASE_URL: AUTH_URL,
} = process.env;

const Header = () => {
  return (
    <header>
      <a href={CONNECT_CANADA_URL} className="logo-container">
        <img src={`${process.env.PUBLIC_URL}/company-logo.png`} alt="Connect Canada Logo" />
      </a>
      <a href={`${AUTH_URL}/user/profile`} className="home-container" id="home-link">
        <img src={`${process.env.PUBLIC_URL}/home.png`} alt="Home Icon" />
      </a>
    </header>
  );
};

export default Header;